@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, .App {
  font-family: 'Source Sans Pro', sans-serif;
  height: 100%;
  width: 100%;
}

.mapboxgl-popup {
  font-family: 'Source Sans Pro', sans-serif;
  max-width: none !important;
}

.mapboxgl-popup-content {
  padding: 0 !important;
}

.popup-as-tooltip .mapboxgl-popup-tip {
  display: none;
}


.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
  @apply mt-4 mr-4 md:mt-5 md:mr-5;
}

/* hubspot form styling */

.hs-error-msg {
  @apply text-xs text-aptred;
}

.hs-input {
  @apply block w-full px-4 py-2 leading-tight text-gray-700 bg-gray-200 border border-gray-200 rounded appearance-none focus:outline-none focus:bg-white focus:border-gray-500;
}

.hs-form-field {
  @apply mb-2
}

.hs-button {
  @apply cursor-pointer flex items-center px-4 text-white transition-all duration-200 h-9 bg-aptred rounded-3xl mx-auto mt-6
}

.hs-error-msgs {
  @apply text-aptred;
}